<template>
  <div>
    <div class="air__utils__heading">
      <div class="row">
        <div class="col-md-4">
          <h5>
            <span class="mr-3">Ubah Forum</span>
          </h5>
        </div>
        <div class="col-md-8">
          <div class="d-flex flex-column justify-content-center pull-right" v-if="status == 0">
            <button class="btn btn-with-addon mr-auto btn-primary" @click="showModalPin">
                  <span class="btn-addon">
                    <i class="btn-addon-icon fe fe-bell"></i>
                  </span>
              Sematkan
            </button>
            <a-modal
                title="Tolak Akun"
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="handleOkPin"
                @cancel="handleCancelPin"
            ><p>Apakah anda yakin menyematkan topik ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center pull-right" v-else>
            <button class="btn btn-with-addon mr-auto btn-primary" @click="showModalUnpinned">
                  <span class="btn-addon">
                    <i class="btn-addon-icon fe fe-bell-off"></i>
                  </span>
              Unpin
            </button>
            <a-modal
                title="Tolak Akun"
                :visible="visibilityUnpinned"
                :confirm-loading="confirmLoading"
                @ok="handleOkUnpin"
                @cancel="handleCancelUnpin"
            ><p>Apakah anda yakin melepaskan pin topik ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center pull-right mr-2">
            <button class="btn btn-with-addon mr-auto btn-primary" @click="showModalCommentSettings">
              <span class="btn-addon">
                <i class="btn-addon-icon fe fe-settings"></i>
              </span>
              Pengaturan Komentar
            </button>
            <a-modal
              title="Pengaturan Komentar"
              :visible="visibilityCommentSettings"
              :confirm-loading="confirmLoading"
              @ok="handleOkSettings"
              @cancel="handleCancelSettings">
              <a-form>
                <a-form-item label='Aktifkan Komentar'>
                  <a-select
                    placeholder="Pilih Opsi"
                    style="width: 100%"
                    :defaultValue="isAdminOnlyLabel"
                    @select="onSelectCommentOptions">
                    <a-select-option value=1>Hanya Admin</a-select-option>
                    <a-select-option value=0>Semua Pengguna</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label='Akses Forum'>
                  <a-select
                    label-in-value
                    :placeholder="isAllParticipantLabel"
                    style="width: 100%"
                    @select="onSelectAccess">
                    <a-select-option value='1'>Semua Participant</a-select-option>
                    <a-select-option value='0'>{{ isAllParticipantLabel }}</a-select-option>
                  </a-select>
                  <UserAssignment
                    :defaultUsers="choosedUserIds"
                    :visible="userAssignmentVisible"
                    @onSubmit="onSubmitAssignment"
                    @onCancel="onCancelAssignment"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-form :form="form" @submit="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <a-form-item label="Judul" class="col-md-12">
                      <a-input
                          placeholder="Judul forum"
                          v-decorator="[
                          'title',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Judul minimal 3 karakter!'
                              },
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="Isi forum" class="col-md-12">
                      <quill-editor
                          theme="snow"
                          class="editor"
                          ref="myTextEditor"
                          :options="editorOption"
                          @change="onEditorChange"
                          v-model="content"
                          :value="content"
                      />
                    </a-form-item>
                    <a-form-item label="Kategori" class="col-md-6">
                      <a-select
                          placeholder="Pilih kategori"
                          show-search
                          :filter-option="filterOption"
                          :not-found-content="null"
                          style="width: 100%"
                          v-decorator="[
                          'category_id',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              }
                            ]
                          }
                        ]"
                      >
                        <a-select-option
                            v-for="category in categories"
                            :key="category.id"
                        >{{ category.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="Upload Foto" class="col-md-3">
                      <a-upload
                          name="image"
                          listType="picture-card"
                          class="avatar-uploader"
                          :showUploadList="false"
                          :beforeUpload="beforeUpload"
                          @change="onFileChange"
                      >
                        <img
                            v-if="imageUrl"
                            :src="imageUrl"
                            alt="image"
                            class="img-fluid"
                        />
                        <div v-else>
                          <a-icon :type="loading ? 'loading' : 'plus'"/>
                          <div class="ant-upload-text">Pilih Foto</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                  </div>
                </div>

                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-with-addon">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import hljs from 'highlight.js';
import {getDetailForum, getListCategoryForum, pinTopic, unpinTopic, updateForum, updateSettings} from "@/api/forum.api";
import UserAssignment from "../create/UserAssignment.vue";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  components: {
    UserAssignment
  },
  props: {
    id: String
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'font': []}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        },
      },
      form: this.$form.createForm(this),
      categories: [],
      imageUrl: '',
      thumbnail: null,
      loading: false,
      content: ``,
      visible: false,
      confirmLoading: false,
      status: '',
      visibilityUnpinned: false,
      visibilityCommentSettings: false,
      isAdminOnlyLabel: null,
      isAdminOnly: null,
      isAllParticipantLabel: 'Pilih Pengguna',
      userAssignmentVisible: false,
      choosedUsers: [],
      choosedUserIds: [],
      rules: {
        isAdminOnly: [
          'is_admin_only', {
            rules: [{
              required: true,
              message: 'Opsi tidak boleh kosong!'
            }]
          }
        ]
      }
    }
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value
    }
  },
  mounted() {
    this.fetchCategories();
    this.fetchDetailContent()
  },
  methods: {
    onSubmitAssignment(users) {
      this.userAssignmentVisible = false
      if(users.length == 0) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Daftar pengguna tidak boleh kosong"
        });
        return
      }
      this.choosedUsers = users.map(it => it.id)
      this.handleOkSettings()
    },
    onCancelAssignment() {
      this.userAssignmentVisible = false
    },
    onEditorChange: debounce(function (value) {
      this.content = value.html
    }, 466),
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.set("title", value.title);
          formData.set("body", this.content);
          formData.set("category_id", value.category_id);
          if (this.thumbnail != null) {
            formData.set("image", this.thumbnail);
          }
          if(!this.content){
            this.$notification["warning"]({
              message: "Invalid",
              description: "Isi forum tidak boleh kosong!"
            });
            this.$nprogress.done();
          }else {
            this.submitData(formData);
          }
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    },
    beforeUpload() {
      return false;
    },
    onFileChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
        this.thumbnail = info.file;
      }
    },
    checkImageUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
      var validFormat = true;
      if (!isJpgOrPng) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Format Foto tidak Valid"
        });
        validFormat = false;
      }
      const isLt5M = file.size / 1024 <= 20480;
      if (!isLt5M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
        validFormat = false;
      }
      return validFormat;
    },
    async fetchCategories() {
      try {
        let params = {
          limit: 200
        };
        let result = await getListCategoryForum(params);
        this.categories = result.data.results;
      } catch (error) {/**/
      }
    },
    async fetchDetailContent() {
      getDetailForum(this.id).then((response) => {
        if (!response) {
          this.$router.push('/forum')
        }
        this.content = response.data.body;
        this.status = response.data.is_pinned;
        this.isAdminOnly = response.data.is_admin_only;
        this.isAdminOnlyLabel = response.data.is_admin_only == 1 ? "Hanya Admin" : "Semua Pengguna";
        // this.isAllParticipant = { key: response.data.is_all_participant.toString() }
        this.isAllParticipantLabel = response.data.is_all_participant == 1 
          ? "Pilih Pengguna"
          : `Total Pengguna (${response.data.participant.length })`
        this.choosedUserIds = response.data.participant.map(it => it.id);
        this.choosedUsers = response.data.participant.map(it => it.id)
        this.form.setFieldsValue({title: response.data.title});
        this.form.setFieldsValue({category_id: response.data.category.id});
        if (response.data.image.url != null) {
          this.imageUrl = response.data.image.url;
        }
      })
    },
    async submitData(params) {
      try {
        let response = await updateForum(params, this.id);
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil memperbaharui forum"
          });
          await this.$router.push("/forum");
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
    showModalPin() {
      this.visible = true;
    },
    handleOkPin() {
      this.confirmLoading = true;
      this.pinForum();
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancelPin() {
      this.visible = false;
    },
    async pinForum() {
      try {
        let response = await pinTopic(this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Topik berhasil disematkan"
          });
          await this.$router.push("/forum")
        }
      } catch (error) {/**/
      }
    },
    showModalUnpinned() {
      this.visibilityUnpinned = true;
    },
    showModalCommentSettings() {
      this.visibilityCommentSettings = true;
    },
    handleOkUnpin() {
      this.confirmLoading = true;
      this.unpinForum();
      setTimeout(() => {
        this.visibilityUnpinned = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancelUnpin() {
      this.visibilityUnpinned = false;
    },
    onSelectCommentOptions(value) {
      if(value != null && value != undefined) {
        this.isAdminOnly = parseInt(value);
        this.isAdminOnlyLabel = value == '1'
          ? 'Hanya Admin'
          : 'Semua Pengguna'
      }
    },
    onSelectAccess(value) {
      if(value != null && value != undefined) {
        if(value.key == '1') {
          this.choosedUsers = [];
          this.handleOkSettings()
        } else if(value.key == '0') {
          this.userAssignmentVisible = true
        }
      }
    },
    async handleOkSettings() {
      try {
        this.confirmLoading = true;
        const params = new FormData();
        params.set('pin', this.status);
        params.set('admin_only', this.isAdminOnly);
        this.choosedUsers.forEach((it, idx) => {
          params.set(`users[${idx}]`, it)
        })
        let response = await updateSettings(this.id, params);
        if (response) {
          this.visibilityCommentSettings = false
          this.confirmLoading = false;
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil memperbaharui pengaturan komentar"
          });
          await this.$router.push("/forum");
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
    handleCancelSettings() {
      this.visibilityCommentSettings = false
    },
    async unpinForum() {
      try {
        let response = await unpinTopic(this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Pin topik berhasil dilepas"
          });
          await this.$router.push("/forum")
        }
      } catch (error) {/**/
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }
}
</style>
